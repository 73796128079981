import {navigate} from 'gatsby-link';
import React from 'react';
import Button from '../../button';
import picture from '../../../images/tools/text-to-speech/one.png';
import picture2 from '../../../images/tools/text-to-speech/two.png';
import picture3 from '../../../images/tools/text-to-speech/three.png';
import {textToSpeechCountries} from '../../../helpers/constants';
import './styles.scss';

const Lang = ({country, lang}: any) => (
  <div className="language d-flex justify-content-center align-items-center m-2 p-2">
    <div>
      <div className="text-center">{country}</div>
      <div className="text-center">{lang}</div>
    </div>
  </div>
);
const TextToSpeechInfo = () => (
  <div className="row text-to-speech-info mx-0">
    <div className="col-12 col-md-6 mb-5 p-2">
      <h4 className="mb-4">Say goodbye to robotic sounding voices</h4>
      <div className="mb-2">
        Featuring high fidelity TTS WaveNet voices, our text to speech tool reads text aloud and enables you to download
        voice audio in MP3 format.
      </div>
      <div className="mb-4">
        Easily convert US or UK English to native and realistic speech, ideal to create short intro voice messages, read
        aloud content or create audio podcasts from your existing content.
      </div>
      <div>
        <Button onClick={() => navigate('/registration')}>Sign up to Hexomatic account to increase your limits</Button>
      </div>
    </div>
    <div className="col-12 col-md-6 d-flex align-items-center justify-content-center mb-5">
      <img src={picture} alt="text to speech" />
    </div>
    <div className="col-12 col-md-6 d-flex align-items-center justify-content-center mb-5 change-order-2">
      <img src={picture2} alt="text to speech" />
    </div>
    <div className="col-12 col-md-6 mb-5 change-order-1 ">
      <h4 className="mb-4">Automate time consuming text to speech tasks with Hexomatic</h4>
      <div className="mb-2">
        Hexomatic is a unique work automation platform that enables you to tap into 100+ ready made automations to
        create your perfect workflow.
      </div>
      <div className="mb-4">
        Translate to 80+ languages, create realistic audio messages at scale or convert your latest blog posts to MP3
        audio podcasts on autopilot.
      </div>
      <div className="mb-4">
        Hexomatic provides cost effective text to speech using the most realistic Google AI TTS WaveNet voices via a
        simple point and click interface.
      </div>
      <div>
        <Button onClick={() => navigate('/registration')}>Click here to find out more</Button>
      </div>
    </div>
    <div className="col-12 col-md-6 mb-5 change-order-3">
      <h4 className="mb-4">How does text to speech software work?</h4>
      <div className="mb-2">
        Text to speech software is a very powerful tool that can help you convert text into audio files using AI and
        machine learning trained on human voices. It can be used in a wide range of applications that include creating
        personal voice messages, providing audio for visually impaired users, audio books and courses.
      </div>
      <h5 className="mb-2 multilingual-space">Multilingual natural voices for a global audience</h5>
      <div className="mb-4">
        Create high-quality natural-sounding male or female voices for up to 40 different languages including:
      </div>
    </div>
    <div className="col-12 col-md-6 d-flex align-items-center justify-content-center mb-5 change-order-4">
      <img src={picture3} alt="text to speech" />
    </div>
    <div className="col-12 mb-2 d-flex countries change-order-5">
      {textToSpeechCountries.map(({country, lang}) => (
        <Lang country={country} lang={lang} />
      ))}
    </div>
  </div>
);
export default TextToSpeechInfo;
