import React from 'react';
import TextToSpeechAction from './action';
import TextToSpeechInfo from './info';
const TextToSpeechWrapper = () => (
  <>
    <TextToSpeechAction />
    <TextToSpeechInfo />
  </>
);
export default TextToSpeechWrapper;
