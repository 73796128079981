import React from 'react';
import FAQItem from '../../../components/FAQ-item';
import Layout from '../../../components/layout/layout';
import {PageHero} from '../../../components/page-hero';
import Section from '../../../components/section';
import TextToSpeechWrapper from '../../../components/tools-components/text-to-speech';

const TextToSpeech = () => (
  <Layout>
    <PageHero
      title="Online text to speech generator with realistic AI voices"
      titleSmall
      subtitle="Turn any text into the most natural-sounding speech powered by Hexomatic."
      className="toolPage"
      id='seo_home_area_tools'
    />
    <Section className="m-auto">
      <TextToSpeechWrapper />
    </Section>
    <Section>
      <div className="col-12 d-block mb_20 m-auto" style={{maxWidth: '800px', width: '100%'}}>
        <h4 className="mb-4 mt-4 text-center">Text to Speech FAQ</h4>
        <FAQItem
          title="How do I turn my text into voice?"
          text={
            <p>
              Our text to speech converter allows turning text into voice easily. All you need to do is type or paste
              your text, and select your preferred language and gender. Moreover, you have the option of choosing the
              voice that most suits your project.
            </p>
          }
        />
        <FAQItem
          title="What is an AI voice?"
          text={
            <p>
              AI voices are constructed from machine learning technology. It converts text into speech following human
              sounds. It is used for real-time speech recognition for analyzing calls, visual voicemail, and more. There
              even exist AI speaker personas like Alexa, Siri, etc.
            </p>
          }
        />
        <FAQItem
          title="Is using AI voiceover better than a human voice?"
          text={
            <>
              <p>
                AI voice-overs are not perfect but in most cases, they can hardly be distinguished from a human voice.
                Moreover, over time, AI voices tend to sound more and more human. However, In cases when you need to
                express strong anger or happiness, using an AI voice-over is not the best choice. domain age, the date
                of creation, update, and expiration, as well as WHOis Raw data and DNS records.
              </p>
              <p>
                Instead, using AI voices is cheaper, more scalable, and simple to use. In contrast to a recording of a
                human voice, the script of AI voices can also be updated in real-time, opening up new opportunities for
                personalized advertising.
              </p>
            </>
          }
        />
        <FAQItem
          title="Can AI voices be used for commercial purposes?"
          text={
            <>
              <p>
                The short answer is YES. You can use AI voices for any commercial project. They greatly help businesses
                to cater to non-native speaker consumers, allowing them to cover wider and global markets.
              </p>
              <p>
                For example, using AI voices can be ideal for customer care strategy. Offering multilingual customer
                support can be an excellent strategic advantage for any business. Customers often need support in
                various languages and hiring customer support employees who speak multiple languages can be too costly.
                Using an AI voice can become a saving grace for such situations.
              </p>
            </>
          }
        />
        <FAQItem
          title="How long does it take to convert text to speech?
          "
          text={
            <p>
              Our online text to speech converter takes seconds to generate human-like speech in your desired language.
              Once the audio file is ready, it is available to download in MP3 with a single click.
            </p>
          }
        />
        <FAQItem
          title="What are the most realistic text to speech (TTS) providers?"
          text={
            <>
              <p>
                Right now Google text to speech and Amazon Polly from AWS are the leaders in the TTS space, providing AI
                powered voices that mimic realistic human tones.
              </p>
              <p>Our free tool is powered by Google AI for human sounding audio.</p>
            </>
          }
        />
      </div>
    </Section>
  </Layout>
);

export default TextToSpeech;
